.contact{
    position: relative;
    background: linear-gradient(to right,#fff,#78cd5b,#418E27);
    font-family: "Poppins" sans-serif;
    &__header{
        margin-bottom: 3rem;
    }
    @media (max-width:1100px){
        padding: 2rem;
    }
    &__profile{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 3rem;
        &-item{
            position: relative;
            display: flex;
            align-items: center;
            gap: 1rem;
            img{
                width: 70px;
                height: 90px;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        &-col{
            display: flex;
            flex-direction: column;
            gap:5px;
            font-size: 17px;
            z-index: 1;
        }
        &-bg{
            position: absolute;
            width: 250px;
            height: 100px;
            background: #f6d5a46c;
            border-radius: 5px;
            left: 0%;
        }
    }
    &__socials{
        display: flex;
        flex-wrap: wrap;
        gap:3rem;
        padding-bottom: 3rem;
        padding-top: 3rem;
        &{
            font-size: 20px;
            color: blue;
            text-underline-position: under;
            text-decoration: underline;
        }
    }
    &__footer{
        background: #00000030;
        position: absolute;
        bottom: 0;
        left: 0;
        padding-top: .5rem;
        padding-bottom: .5rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        &-link{
            width: 100%;
            text-align: center;
            font-size: 20px;
            @media (max-width:900px) {
                font-size: 14px;
            }
        }
    }
}
.form{
    &__info{
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 2.2vw;
        font-weight: 500;
        input{
            font-size: inherit;
            font-weight: inherit;
            border: none;
            background:transparent;
            border-bottom: 1px solid #000;
            color:#444;
            &:focus{
                outline: none;
            }
        }
    }
    &__info-law{
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: auto;
        font-weight: 300;
        input{
            min-width: 15px;
            min-height: 15px;
            width: 15px;
            height: 15px;
            font-size: inherit;
            font-weight: inherit;
            border: none;
            background:transparent;
            color:#444;
            &:focus{
                outline: none;
            }
        }
    }
    &__flex{
        display: flex;
        gap: 1rem;
        margin-bottom: 2rem;
        textarea{
            width: 70%;
            background: transparent;
            border:none;
            border-bottom: 1px solid #000;
            font-size: 2.2vw;
            color:#444;
            resize:none;
            &:focus{
                outline: none;
            }
        }
        .coolButton{
            font-weight: 900;
            font-size: 20px;
            &:before{
                background:#000;
            }
            &:after{
                display: none;
            }
            &:hover:before{
                background:#fff;
            }
        }
    }
}
@media (max-width:900px){
    .form{
        &__flex{
            flex-direction: column;
            textarea{
                font-size: 4vw;
            }
        }
        &__info{
            font-size: 4vw;
            textarea,input{
                font-size: 4vw;
            }
        }
    }
}

@media (max-width:800px) {
    .contact .coolButton{
        transform: scale3d(1,1,1);
        font-size: 19px;
    }
}

@media (max-width:610px) {
    .form{
        &__info{
            flex-direction: column;
            align-items: flex-start;
            font-size: 5vw;
            width: 100%;
            input{
                font-size: inherit;
                width: 100%;
            }
        }
        &__flex{
            width: 100%;
            textarea{
                width: 100%;
                font-size: 5vw;
            }
        }
    }
}