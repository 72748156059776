.about{
    &__header{
        font-size: 60px;
        font-style: italic;
        padding-bottom: 10px;
    }
    p{
        font-size: 20px;
        background: rgba(0,0,0,0.1);
        border-radius: 5px;
    }
    &__body{
        display: inline-block;
        grid-template-columns: 2fr 3fr;
        gap: 1rem;
        font-family: "Poppins" sans-serif;
        span{
            border-top: 1px solid #000;
            padding: 1rem;
            font-size: 30px;
            text-align: center;
        }
        &__img{
            position: relative;
            //width: 100vw;
            width: auto;
            height: 700px;
            img{
                width: auto;
                //width: 60vw;
                height: 600px;
                object-fit: cover;
                position: absolute;
                border-radius: 5px;
                top: 1rem;
            }  
        }
        &__infos{
            padding-left: .5rem;
            & p{
                flex-wrap: wrap;
                font-size: 20px;
            }
            & h4{
                font-size: 22px;
            }
            & h1{
                font-size: 35px;
                width: 93%;
                
            }
            @media (min-width:670px) {
                & h4{
                    font-size: 33px;
                }
                & h1{
                    font-size: 40px;
                }
                & p{
                    font-size: 25px;
                }
            }
            @media (min-width:1200px) {
                &{
                    left: 5%;
                }
                & h4{
                    font-size: 36px;
                }
                & h1{
                    font-size: 50px;
                }
                & p{
                    font-size: 25px;
                }
            }
        }
        @media (min-width:1200px) {
            display: grid;
        }
    }
    
}