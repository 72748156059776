@import "./base.scss";

.skills{
    position: relative;
    font-size: 40px;
    height: 300px;
    background: $skills-bg;
    overflow: hidden;
    text-align: center;
    padding-top: 1rem;
    &__header{
        font-weight: 600;
    }
    &__text{
        position: absolute;
        white-space: nowrap;
        &--left{
            left: 0;
            top: 6rem;
            animation: moveleft 60s linear infinite;
            @keyframes moveleft {
                0%{
                    opacity: 0;
                    transform: translate3d(0,0,0);
                }
                5%{
                    opacity: 1;
                    
                }
                90%{
                    opacity: 1;
                }
                100%{
                    transform: translate3d(-80%,0,0);
                    opacity: 0;
                }
            }
        }
        &--right{
            right: 0;
            top: 11rem;
            animation: moveright 60s linear infinite;
            @keyframes moveright {
                0%{
                    opacity: 0;
                    transform: translate3d(0,0,0);
                }
                5%{
                    opacity: 1;
                    
                }
                90%{
                    opacity: 1;
                }
                100%{
                    transform: translate3d(75%,0,0);
                    opacity: 0;
                }
            }
        }
    }
    @media (max-width:700px) {
        &{
            height: 200px;

        }
        &__text{
            font-size: 20px;
            &--left{
                top: 5rem;
            }
            &--right{
                top: 8rem;
            }
        }
    }
}